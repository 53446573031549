import { createRouter, createWebHistory } from 'vue-router';

const HomePage = () => import('../pages/HomePage.vue');
const ProductPage = () => import('../pages/ProductPage.vue');
const AppServicesPage = () => import('../pages/AppServicesPage.vue');
const LearnPage = () => import('../pages/LearnPage.vue');
const EnterprisePage = () => import('../pages/EnterprisePage.vue');
//const AppScreen = () => import('../pages/AppScreen.vue');
//const QrScanner = () => import('../pages/QrScanner.vue');
//const AppHomePage = () => import('../pages/AppHomePage.vue');
//const DevicePaired = () => import('../pages/DevicePaired.vue');
//const AppSettings = () => import('../pages/AppSettings.vue');
//const Transfers = () => import('../pages/Transfers.vue');
//const ReviewPage = () => import('../pages/ReviewPage.vue');
const PaymentProcess = () => import('../pages/PaymentProcess.vue');
const ShippingPage = () => import('../pages/ShippingPage.vue');
//const CartLogin = () => import('../pages/CartLogin.vue');
const GetStarted = () => import('../pages/start.vue');
const ShopPage = () => import('../pages/ShopPage.vue');
const ProductPageBTC = () => import('../pages/ProductPageBTC.vue');
const PaymentOptions = () => import('../pages/PaymentOptions.vue');
const PaymentComplete = () => import('../pages/PaymentComplete.vue');
const BlogPage = () => import('../pages/BlogPage.vue');
const ArticlePage = () => import('../pages/ArticlePage.vue');

export const routes = [
  { path: '/', component: HomePage },
  { path: '/evmproducts', component: ProductPage },
  { path: '/wallet', component: AppServicesPage },
  { path: '/learn', component: LearnPage },
  { path: '/enterprise', component: EnterprisePage },
  //{ path: '/app', component: AppScreen },
  //{ path: '/scanner', component: QrScanner },
  //{ path: '/apphome', component: AppHomePage },
  //{ path: '/devicepaired', component: DevicePaired },
  //{ path: '/appsettings', component: AppSettings },
  //{ path: '/transfers', component: Transfers },
  //{ path: '/reviewpage', component: ReviewPage },
  { path: '/paymentprocess', component: PaymentProcess },
  { path: '/shippingpage', component: ShippingPage },
  //{ path: '/cartlogin', component: CartLogin },
  { path: '/start', component: GetStarted },
  { path: '/shop', component: ShopPage },
  { path: '/btcproducts', component: ProductPageBTC },
  { path: '/paymentoptions', component: PaymentOptions },
  { path: '/paymentcomplete', component: PaymentComplete },
  { path: '/blog', component: BlogPage },
  { 
    path: '/blog/:id', 
    name: 'ArticlePage', 
    component: ArticlePage, 
    props: true 
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; 
    } else {
      return { top: 0 }; 
    }
  }
});

// track page views
router.afterEach((to, from) => {
  if (typeof window.gtag === 'function') {
    window.gtag('config', 'G-41MSPKQTCK', {
      page_path: to.fullPath,
    }); 
    window.gtag('config', 'GTM-P3Z8FF6Q', {
      page_path: to.fullPath,
    });
  }
});

export default router;
